.SettingsScreen {
  display: flex;
  height: 100%;
}

.SettingsScreen-leftCol {
  width: 50%;
  padding-left: 16px;
  padding-right: 16px;
}

.SettingsScreen-rightCol {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--theme-border);
  width: 50%;
}

.SettingsScreen-formTitle {
  height: 50vh;
  padding-left: 16px;
  padding-right: 16px;
}

.SettingsScreen-form {
  height: 50vh;
  overflow: hidden;
  border-top: 1px solid var(--theme-border);
  position: relative;
}

.SettingsScreen-row {
  border-top: 1px solid var(--theme-border-aux);
  padding-left: 16px;
  padding-top: 8px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  color: var(--theme-block-text);
  height: 40px;
}

.SettingsScreen-rowNote {
  color: var(--theme-link-aux);
  margin-top: -8px;
}

.SettingsScreen-row:first-child {
  margin-top: -1px;
}

.SettingsScreen-signoutBtn {
  position: absolute;
  right: 0;
  bottom: 0;
}

@keyframes up-and-down {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(calc(100vh + 50%));
  }
}

@keyframes increase {
  0% {
    transform: translateY(0%) scaleY(0);
  }
  50% {
    transform: translateY(0%) scaleY(1);
  }
  100% {
    transform: translateY(100%) scaleY(0);
  }
}

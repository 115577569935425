.AccountDisabled {
  display: flex;
  height: 100%;
}

.AccountDisabled-leftCol {
  width: 50%;
  border-right: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AccountDisabled-header {
  padding-left: 16px;
  padding-right: 16px;
}

.AccountDisabled-footer {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
}

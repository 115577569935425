.icon {
  display: inline-block;
  vertical-align: top;
}

.link {
  /* border-bottom: 1px solid #000; */
  color: inherit;
  text-decoration: underline;
}

.link:focus,
.link:hover {
  text-decoration: none;
}

/* Resets outline styles for any button.link */
.link:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

.squareBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
}

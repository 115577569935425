.RoomComposer {
  height: 100vh;
  background: #fff;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

.RoomComposer-header {
  display: flex;
  background-color: #fff;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.RoomComposer-backBtn {
  margin-right: 14px;
}

.RoomComposer-areaTitle {
  padding-top: 10px;
}

.RoomComposer-areaTitleInput {
  border: 0;
}

.RoomComposer-areaTitleInput:focus {
  outline: 0;
}

.RoomComposer-areaTitleInput::placeholder {
  opacity: 1;
  color: #000;
}

.RoomComposer-areaTitleInput:focus::placeholder {
  opacity: 0.2;
}

.RoomComposer-areaTitle a {
  color: #999;
}

.RoomComposer-areaTitle a:hover {
  color: #000;
}

.RoomComposer-mid {
  flex-grow: 1;
  padding: 5%;
  display: flex;
  flex-direction: column;
}

.RoomComposer-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.RoomComposer-wall {
  width: 1600px;
  height: 900px;
  border: 1px solid #000;
  position: absolute;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.RoomComposer--fade .RoomComposer-wall {
  border-color: #f2f2f2;
}

.RoomComposer-wall.is-warning {
  border: 1px dashed #dc3723;
}

.RoomComposer-wall.is-dragging {
  background: #e0ebe8;
}

.RoomComposer-wallSize {
  position: absolute;
  bottom: 8px;
  left: 8px;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
}

.RoomComposer-wallSize button {
  width: auto;
  margin-left: 10px;
}

.RoomComposer-sliderContainer {
  position: absolute;
}

.RoomComposer-sliderContainer--top {
  transform: translate(0, -100%);
  left: 0;
  right: 0;
  top: 19px;
  height: 60px;
}

.RoomComposer-sliderContainer--top .ScaleSlider-inner {
  left: 0;
  right: 0;
  bottom: 20px;
}

.RoomComposer-sliderContainer--bottom {
  transform: translate(0, 100%);
  left: 0;
  right: 0;
  bottom: 19px;
  height: 60px;
}

.RoomComposer-sliderContainer--bottom .ScaleSlider-inner {
  left: 0;
  right: 0;
  top: 19px;
}

.RoomComposer-sliderContainer--right {
  transform: translate(100%, 0%);
  /* right: 0; */
  top: 0;
  bottom: 0;
  right: 19px;
  width: 60px;
}

.RoomComposer-sliderContainer--right .ScaleSlider-inner {
  top: 0;
  bottom: 0;
  left: 19px;
}

.RoomComposer-sliderContainer--left {
  transform: translate(-100%, 0%);
  top: 0;
  bottom: 0;
  left: 19px;
  width: 60px;
}

.RoomComposer-sliderContainer--left .ScaleSlider-inner {
  top: 0;
  bottom: 0;
  right: 20px;
}

.RoomComposer-zeroState {
  width: 194px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border: 1px solid transparent;
  transition: border-color 0.1s ease-in-out;
}

.RoomComposer-zeroState:hover {
  border-color: #000;
}

.RoomComposer-zeroStateInner {
  display: flex;
  height: 259px;
  background: #f2f2f2;
}

.RoomComposer-wallLine {
  width: 500px;
  height: 0;
  border-top: 1px solid #000;
  position: absolute;
  transition: border-color 0.2s ease;
}

.RoomComposer--fade .RoomComposer-wallLine {
  border-color: #f2f2f2;
}

.RoomComposer-openPickerBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  color: #000;
}

.RoomComposer-picker {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.RoomComposer-draggable {
  cursor: grabbing;
}

.RoomComposer-boundsWarning {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.RoomComposer-boundsWarningInner {
  background-color: #dc3723;
  color: #fff;
  padding: 14px 16px;
}

.RoomComposer-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.AcknowledgementDialog {
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.AcknowledgementDialog-box {
  background-color: var(--theme-block-bg);
  color: var(--theme-block-text);
  width: 392px;
}

.AcknowledgementDialog-header {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
}

.AcknowledgementDialog-title {
  padding-top: 10px;
  padding-bottom: 10px;
}

.AcknowledgementDialog-closeBtn {
}

.AcknowledgementDialog-inner {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 38px;
}

.AcknowledgementDialog-msg {
  margin-top: 16px;
}

.AcknowledgementDialog-actions {
  display: flex;
  justify-content: flex-end;
}

.AcknowledgementDialog-actionsInner {
  display: flex;
}

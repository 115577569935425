.ArtworkSizeSlider {
  display: flex;
}

.ArtworkSizeSlider-cancelBtn {
  border-right: 1px solid var(--color-gray-80);
}

.ArtworkSizeSlider-applyBtn {
  border-left: 1px solid var(--color-gray-80);
}

.ArtworkSizeSlider-inner {
  flex-grow: 1;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  background-color: var(--color-gray-90);
}

.ArtworkSizeSlider .Button {
  position: relative;
}

.ArtworkSizeSlider .Button:hover .tooltip {
  opacity: 1;
}

.ArtworkSizeSlider .tooltip {
  top: -5px;
}

.ArtworkPicker {
  background-color: transparent;
  position: relative;
  z-index: 0;
}

.ArtworkPicker.is-open {
  background-color: #fff;
}

.ArtworkPicker:not(.is-open),
.ArtworkPicker:not(.is-open) .SearchField-input {
  cursor: pointer;
}

.ArtworkPicker-title {
  padding-top: 7px;
}

.ArtworkPicker-header {
  display: flex;
  margin-top: -40px;
  border-top: 1px solid transparent;
  justify-content: space-between;
  /* transition: background-color 0.1s ease-in-out; */
}

.ArtworkPicker:hover .ArtworkPicker-header,
.ArtworkPicker.is-open .ArtworkPicker-header {
  background-color: var(--theme-input-hover);
}

.ArtworkPicker.is-open .ArtworkPicker-header {
  border-top-color: #000;
}

.ArtworkPicker-searchField {
  background-color: #fff;
  /* transition: background-color 0.s ease-in-out; */
}

.ArtworkPicker:hover .ArtworkPicker-searchField,
.ArtworkPicker.is-open .ArtworkPicker-searchField {
  flex-grow: 1;
  background-color: transparent;
}

.ArtworkPicker .ArtworkPicker-searchField .SearchField-input {
  background-color: transparent;
}

/* .ArtworkPicker:hover .ArtworkPicker-searchField .SearchField-input,
.ArtworkPicker.is-open .ArtworkPicker-searchField .SearchField-input {
  background-color: var(--theme-input-hover);
} */

.ArtworkPicker.is-open
  .ArtworkPicker-searchField
  .SearchField-input::placeholder {
  color: #666666;
}

.ArtworkPicker-toggleBtn {
  position: relative;
}

.ArtworkPicker:not(.is-open) .ArtworkPicker-toggleBtn {
  background-color: #fff;
  color: #000;
}

.ArtworkPicker:not(.is-open):hover .ArtworkPicker-toggleBtn {
  background-color: transparent;
}

.ArtworkPicker:not(.is-open) .ArtworkPicker-toggleBtn:hover {
  background-color: #f2f2f2;
}

.ArtworkPicker:not(.is-open) .ArtworkPicker-toggleBtn:hover .tooltip {
  opacity: 1;
}

.ArtworkPicker-main {
  display: flex;
}

.ArtworkPicker-scroll {
  overflow-x: auto;
  flex-grow: 1;
}

.ArtworkPicker-inner {
  display: flex;
  flex-shrink: 0;
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  margin-left: -32px;
}

.ArtworkPicker-inner > * {
  margin-left: 16px;
  flex-shrink: 0;
}

.ArtworkPicker-inner > *:last-child {
  padding-right: 16px;
  width: calc(96px + 16px);
}

.ArtworkPicker-artwork {
  cursor: grab;
  margin-top: -16px;
  margin-bottom: -16px;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: hidden;
  width: 96px;
  height: calc(96px + 32px);
  position: relative;
}

.ArtworkPicker-artwork.is-hidden {
  width: 0;
  height: 0;
  margin-left: 0;
  top: 50px;
  left: 50px;
}

.ArtworkPicker-artwork img {
  width: 100%;
  height: 100%;
}

.ArtworkPicker-add {
  width: calc(96px + 32px);
  flex-shrink: 0;
  padding: 16px;
}

.ArtworkPicker-addInner {
  height: 96px;
  border: 1px solid #000;
  display: flex;
}

.ArtworkPicker-loadingBox {
  height: 96px;
  display: flex;
  background-color: #f2f2f2;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 96px;
}

.ArtworkPicker-loadingBox:last-child {
  padding-right: 0;
  margin-right: 16px;
  width: 96px !important;
}

.ArtworkPicker-zero {
  height: 96px;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.ArtworkPicker-zero {
  width: auto !important;
}

.ArtworkPicker-zero .link {
  margin-left: 10px;
}

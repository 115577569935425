.Slider {
  height: 11px;
  width: 100%;
  position: relative;
}

.Slider-track {
  border-top: 1px solid var(--theme-border);
  margin-top: 5px;
}

.Slider-handleArea {
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  position: absolute;
  cursor: grab;
}

.Slider-handleArea:active {
  cursor: grabbing;
}

.Slider-handleArea:active .tooltip {
  opacity: 1;
}

.Slider-handleArea .tooltip--top {
  top: -5px;
}

.Slider-handleArea .tooltip--bottom {
  bottom: -5px;
}

.Slider-handle {
  width: 8px;
  height: 11px;
  position: relative;
  background-color: var(--theme-border);
  color: #000;
}

.Slider-handle::before,
.Slider-handle::after {
  content: '';
  position: absolute;
  top: 2px;
  width: 1px;
  height: 7px;
  background-color: currentColor;
}

.Slider-handle::before {
  left: 2px;
}

.Slider-handle::after {
  right: 2px;
}

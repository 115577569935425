.RoomWallArtwork {
  position: absolute;
  left: 0;
  top: 0;
  cursor: grab;
  /* overflow: hidden; */
  width: 0;
  height: 0;
  transition: opacity 0.2s ease-in-out;
}

.RoomWallArtwork.is-selected {
  /* outline: 2px solid blue; */
}

.RoomWallArtwork.is-dragging {
  cursor: grabbing;
  opacity: 0.9;
}

.RoomWallArtwork-deleteBtnContainer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.RoomWallArtwork-deleteBtn {
  background-color: var(--color-ui-error-50);
  transition: background-color 0.1s ease-out;
}

.RoomWallArtwork-deleteBtn:hover {
  background-color: var(--color-ui-error-60);
}

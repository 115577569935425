.tooltip {
  --background-color: #000;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  background: var(--background-color);
  color: #fff;
  padding: 10px 8px;
  transition: opacity 0.2s ease-in-out;
  white-space: nowrap;
}

.tooltip--top {
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -100%, 0);
}

.tooltip--bottom {
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 100%, 0);
}

.tooltip--right {
  top: 50%;
  right: 0;
  transform: translate3d(100%, -50%, 0);
}

.tooltip--left {
  left: 0;
  top: 50%;
  transform: translate3d(-100%, -50%, 0);
}

.tooltip::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 7px solid transparent;
}

.tooltip--top::after {
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 100%, 0) translate3d(0, -2px, 0);
  border-top-color: var(--background-color);
}

.tooltip--right::after {
  left: 0;
  top: 50%;
  transform: translate3d(-100%, -50%, 0) translate3d(2px, 0, 0);
  border-right-color: var(--background-color);
}

.tooltip--bottom::after {
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -100%, 0) translate3d(0, 2px, 0);
  border-bottom-color: var(--background-color);
}

.tooltip--left::after {
  right: 0;
  top: 50%;
  transform: translate3d(100%, -50%, 0) translate3d(-2px, 0px, 0);
  border-left-color: var(--background-color);
}

@font-face {
  /* src: url('../fonts/soehne/soehne-test-buch.woff') format('woff'); */
  src: url('../fonts/Sohne-Buch.otf') format('opentype');
  font-family: 'Soehne';
  font-weight: 400;
  font-style: normal;
}

@font-face {
  /* src: url('../fonts/soehne/soehne-test-halbfett.woff') format('woff'); */
  src: url('../fonts/Sohne-Halbfett.otf') format('opentype');
  font-family: 'Soehne';
  font-weight: 600;
  font-style: normal;
}

@font-face {
  /* src: url('../fonts/soehne/soehne-test-buch-kursiv.woff') format('woff'); */
  src: url('../fonts/Sohne-BuchKursiv.otf') format('opentype');
  font-family: 'Soehne';
  font-style: italic;
  font-weight: 400;
}

@font-face {
  src: url('../fonts/JJannon/JJannon-Bold.otf') format('opentype');
  font-family: 'JJannon';
  font-weight: 600;
  font-style: normal;
}

@font-face {
  src: url('../fonts/JJannon/JJannon-BoldItalic.otf') format('opentype');
  font-family: 'JJannon';
  font-weight: 600;
  font-style: italic;
}

@font-face {
  src: url('../fonts/JJannon/JJannon-Book.otf') format('opentype');
  font-family: 'JJannon';
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url('../fonts/JJannon/JJannon-BookItalic.otf') format('opentype');
  font-family: 'JJannon';
  font-weight: 400;
  font-style: italic;
}

@font-face {
  src: url('../fonts/JJannon/JJannon-ExtraboldItalic.otf') format('opentype');
  font-family: 'JJannon';
  font-weight: 800;
  font-weight: italic;
}

@font-face {
  src: url('../fonts/JJannon/JJannon-Extrabold.otf') format('opentype');
  font-family: 'JJannon';
  font-weight: 800;
  font-style: normal;
}

@font-face {
  src: url('../fonts/JJannon/JJannonDisplay-Italic.otf') format('opentype');
  font-family: 'JJannon Display';
  font-weight: 400;
  font-weight: italic;
}

@font-face {
  src: url('../fonts/JJannon/JJannonDisplay-LightItalic.otf') format('opentype');
  font-family: 'JJannon Display';
  font-weight: 300;
  font-weight: italic;
}

@font-face {
  src: url('../fonts/JJannon/JJannonDisplay-Regular.otf') format('opentype');
  font-family: 'JJannon Display';
  font-weight: 400;
}

@font-face {
  src: url('../fonts/JJannon/JJannonDisplay-Regular.otf') format('opentype');
  font-family: 'JJannon Display';
  font-weight: 400;
  font-style: normal;
}

.RoomsIndexPreviewable {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: -40px;
}

.RoomsIndexPreviewable-room {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.RoomsIndexPreviewable.has-current-room .RoomsIndexPreviewable-room {
  opacity: 1;
}

.RoomsIndexPreviewable-main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.RoomsIndexPreviewable-list {
  padding-left: 16px;
  padding-bottom: 50vh;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.RoomsIndexPreviewable-list > li:first-child > .RoomsIndexPreviewable-listItem {
  /* margin-top: -1em; */
}

.RoomsIndexPreviewable.is-loading-room .RoomsIndexPreviewable-list {
  opacity: 0;
  pointer-events: none;
}

.RoomsIndexPreviewable.is-loading-room .IndexLayout-header {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.RoomsIndexPreviewable-listItem {
  transition: color 0.1s ease-in-out;
}

.RoomsIndexPreviewable.has-current-room .RoomsIndexPreviewable-listItem,
.RoomsIndexPreviewable.is-see-all-active .RoomsIndexPreviewable-listItem {
  color: var(--color-gray-50);
}

.RoomsIndexPreviewable.has-current-room
  .RoomsIndexPreviewable-listItem.is-current,
.RoomsIndexPreviewable.is-see-all-active
  .RoomsIndexPreviewable-listItem.is-current {
  color: var(--color-black);
}

.RoomsIndexPreviewable-listItemLink {
  /* display: block; */
}

.RoomsIndexPreviewable-listItemSeeAll {
  margin-top: 1em;
}

.RoomsIndexPreviewable-listItemSeeAll:hover {
  color: var(--color-black);
}

.RoomsIndexPreviewable .IndexLayout-header,
.RoomsIndexPreviewable.IndexLayout-headerBar {
  transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
  will-change: opacity;
  /* z-index: 999; */
}

.RoomsIndexPreviewable .IndexLayout-header {
  z-index: 3;
  height: 50vh;
}

.RoomsIndexPreviewable .IndexLayout-title {
  z-index: 3;
}

.RoomsIndexPreviewable .IndexLayout-headerBar {
  z-index: 0;
}

.RoomsIndexPreviewable .IndexLayout-headerBar {
  left: 0;
  padding-left: calc(16px + var(--fixed-offset-left));
}

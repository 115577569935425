:root {
  --fixed-offset-left: 40px;
  --header-height-offset: -132px;
}

.IndexLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.IndexLayout-header {
  height: calc(50vh + var(--header-height-offset));
  flex-shrink: 0;
}

.IndexLayout-headerBar {
  position: fixed;
  left: var(--fixed-offset-left);
  right: 0;
  height: 40px;
  background-color: var(--theme-block-bg);
  color: var(--theme-block-text);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
  padding-left: 16px;
}

.IndexLayout-headerBarTitle {
  padding-top: 10px;
}

.IndexLayout-title {
  display: inline-block;
  vertical-align: top;
  padding: 0 16px;
  position: fixed;
  z-index: 2; /* Above -headerBar */
}

.IndexLayout-titleInner {
  transform-origin: 0 0;
}

.IndexLayout-main {
  flex-grow: 1;
  position: relative;
  z-index: 0;
}

.IndexLayout-actions {
  display: flex;
}

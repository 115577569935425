.SearchField {
  display: flex;
  position: relative;
  z-index: 0;
}

.SearchField-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}

.SearchField-input {
  height: 40px;
  padding: calc(8.5px + var(--font-alignment-px-offset)) 0 10px 40px;
  outline: 0;
  width: 100%;
  transition: background-color 0.1s ease-in-out;
}

.SearchField-input::placeholder {
  color: #000;
  opacity: 1;
}

.SearchField-input:hover,
.SearchField-input:focus {
  background-color: var(--theme-input-hover);
}

.SearchField-input:hover::placeholder,
.SearchField-input:focus::placeholder {
  color: #666;
}

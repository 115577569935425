.DetailPanel {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0px);
}

.DetailPanel-content {
  position: absolute;
  overflow-y: auto;
  left: 162px;
  right: 0;
  bottom: 0;
  top: 0;
  width: auto;
  padding: 0;
  background-color: #fff;
  z-index: 0;
}

.DetailPanel-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

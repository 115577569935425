.IndexTable {
  border-collapse: collapse;
  width: 100%;
  transition: opacity 0.05s ease;
  position: relative;
  z-index: 0;
  flex-grow: 1;
}

.IndexTable.is-loading {
  opacity: 0.5;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.IndexTable-header {
  display: flex;
  width: 100%;
  padding-top: 100px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.IndexTable-headerCell {
  border-bottom: 1px solid #000;
  padding-right: 16px;
  padding-left: 16px;
  text-align: left;
  font-weight: normal;
  display: flex;
  align-items: flex-end;
}

.IndexTable-row {
  display: flex;
  width: 100%;
  transition: background-color 0.1s ease-in-out;
  position: relative;
}

.IndexTable-row:hover {
  background-color: var(--color-gray-5);
}

.IndexTable-row.is-loading {
  background-color: var(--color-gray-10);
}

.IndexTable-loadingRow {
  pointer-events: none;
}

.IndexTable-loadingCol {
  min-width: 150px;
  width: calc(100% / 12 * 11);
}

.IndexTable-loadingRowText {
  color: var(--color-gray-50);
}

.IndexTable-imagePlaceholder {
  width: 74px;
  height: 74px;
  background: #eee;
}

.IndexTable-imageCol {
  min-width: 106px;
  width: calc(100% / 12);
}

.IndexTable-imageCol > img {
  width: 100%;
  height: 100%;
}

.IndexTable-imageCol.IndexTable-cell {
  display: flex;
}

.IndexTable-titleCol {
  min-width: 150px;
  width: calc(100% / 12 * 4);
}

.IndexTable-artistCol {
  min-width: 150px;
  width: calc(100% / 12 * 4);
}

.IndexTable-yearCol {
  min-width: 150px;
  width: calc(100% / 12);
}

.IndexTable-dateAcquiredCol {
  min-width: 150px;
  width: calc(100% / 12 * 2);
}

.IndexTable-lastCol {
  text-align: right;
  justify-content: flex-end;
}

.IndexTable-row .IndexTable-lastCol.IndexTable-cell {
  transition: opacity 0.2s ease-out;
}

.IndexTable-row:hover .IndexTable-lastCol.IndexTable-cell {
  opacity: 0;
}

.IndexTable-cell {
  padding-top: 11px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.IndexTable-cell.IndexTable-imageCol {
  padding-top: 16px;
  padding-bottom: 16px;
}

.IndexTable-rowDeleteBtn {
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.IndexTable-row:hover .IndexTable-rowDeleteBtn {
  opacity: 1;
}

.IndexTable-rowDeleteBtn .Button {
  color: #fff;
  background-color: var(--color-ui-error-50);
  transition: background-color 0.1s ease-out;
}

.IndexTable-rowDeleteBtn .Button:hover {
  background-color: var(--color-ui-error-60);
}

.IndexTable-rowDeleteBtn .Button:focus {
  box-shadow: none;
}

.IndexTable-empty {
  align-self: center;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  padding-top: 32px;
}

.IndexTable-emptyBtn {
  margin-top: 16px;
}

.IndexTable-loadMore {
  color: var(--color-gray-50);
  padding: 16px;
  text-align: center;
  width: 100%;
}

.IndexTable-headerInner {
  height: 40px;
  display: flex;
  align-items: center;
}

.IndexTable-headerLink {
  padding-left: 12px;
  margin-left: -12px;
  padding-right: 12px;
  transition: background-color 0.1s ease-in-out;
}

.IndexTable-headerLink.is-current {
  padding-right: 4px;
}

.IndexTable-headerLink:hover,
.IndexTable-headerLink.is-current {
  background-color: var(--color-gray-5);
}

.IndexTable-headerLink svg {
  vertical-align: middle;
}

.IndexTable-headerIcon {
  opacity: 0;
}

.IndexTable-headerLink.is-current .IndexTable-headerIcon,
.IndexTable-headerLink:hover .IndexTable-headerIcon {
  opacity: 1;
}

.SystemError {
  padding-left: 16px;
  display: flex;
  height: 40px;
  background-color: #fbebe9;
  color: #000;
  border-bottom: 1px solid #dc3723;
  justify-content: space-between;
}

.SystemError-close {
  margin-top: 8px;
  margin-right: 9px;
  appearance: none;
  background: none;
  border: 0;
  width: auto;
  height: 0;
}

.SystemError-body {
  margin-top: 13px;
}

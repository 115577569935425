.RoomsIndex {
  position: relative;
  display: flex;
  flex-direction: column;
}

.RoomsIndex-rows {
  position: relative;
  z-index: 0; /* below -stickyBar */
  flex-grow: 1;
}

.RoomsIndex-row {
  border-bottom: 1px solid #e6e6e6;
}

.RoomsIndex-zero {
  max-width: 392px;
  padding-top: 32px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.RoomsIndex-zeroBtn {
  margin-top: 16px;
}

.RoomsIndex-loadingRow {
  pointer-events: none;
  display: flex;
  overflow: hidden;
  padding-top: 6px;
}

.RoomsIndex-loadingCol {
  width: 50%;
  padding-left: 16px;
  text-align: left;
  color: var(--color-gray-50);
}

.RoomsIndex-loadingImageCol {
  width: 50%;
  display: flex;
  flex-wrap: nowrap;
  padding-top: 9px;
  padding-bottom: 16px;
  padding-left: 16px;
}

.RoomsIndex-imagePlaceholder {
  width: 100px;
  height: 100px;
  background-color: #eee;
  flex-shrink: 0;
}

.RoomsIndex-imagePlaceholder + .RoomsIndex-imagePlaceholder {
  margin-left: 16px;
}

.RoomsIndex-header {
  display: flex;
  width: 100%;
  padding-top: 100px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.RoomsIndex-headerCell {
  border-bottom: 1px solid #000;
  padding-right: 16px;
  padding-left: 16px;
  text-align: left;
  padding-bottom: 11px;
  font-weight: normal;
}

.RoomsIndex-titleCol {
  width: 25%;
}

.RoomsIndex-dateCol {
  width: 25%;
}

.RoomsIndex-artworksCol {
  width: 50%;
}

.RoomsIndex-loadMore {
  color: var(--color-gray-50);
  padding: 16px;
  text-align: center;
  width: 100%;
}

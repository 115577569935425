.ArtworkSizeEstimator {
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}

.ArtworkSizeEstimator-slider {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  margin: auto;
}

.ArtworkSizeEstimator-mid {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ArtworkSizeEstimator-inner {
  position: relative;
  transform: scale(1.01);
  width: 100%;
}

.ArtworkSizeEstimator-inner .WallSVG {
  width: 100%;
  height: auto;
}

.ArtworkSizeEstimator .LazyImage img {
  display: block;
}

.ArtworkSizeEstimator-artwork {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ArtworkSizeEstimator-bench {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-base-bg);
  color: var(--theme-base-text);
  transition: transform 0.3s ease-in-out;
}

.App.is-error {
  /* transform: translate3d(0, 40px, 0); */
}

.App-error {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
  /* transform: translate(0, -100%); */
}

.App-errorMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.App-toast {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.App-loading,
.App-login {
  background-color: var(--color-block-bg);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.App-loadingLoader {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background-color: #000;
  transform: translateY(0%) scaleY(0);
  transform-origin: 0 0;
  animation: increase 3s infinite cubic-bezier(0.5, 0, 0.3, 1);
}

.App-inner {
  align-self: flex-start;
  justify-content: flex-start;
  text-align: left;
  display: flex;
  width: 100%;
}

.App-sidebar {
  flex-shrink: 0;
  position: relative;
  z-index: 1; /* Above .App-main */
  transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.App-sidebar.is-hidden {
  opacity: 0;
  pointer-events: none;
}

.App-sidebar::before {
  content: '';
  position: fixed;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 0;
}

.App.is-menu-open .App-sidebar::before {
  opacity: 1;
  pointer-events: auto;
}

.App-sidebarInner {
  width: 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: var(--theme-base-bg);
  color: var(--theme-base-text);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.App-sidebarInner:hover {
  background-color: var(--theme-btn-secondary-bg-hover);
}

/* Use absolute-positioned border, so it's above the toggleBtn hover background */
.App-sidebarInner::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 1px solid var(--theme-border);
}

.App-sidebarTop {
  width: 100%;
}

.App-sidebarToggleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.App-sidebarBottom {
  position: relative;
}

.App-sidebarWordmark {
  transform: rotate(-90deg);
  transform-origin: 0% 100%;
  position: absolute;
  left: calc(50% + 10px);
  bottom: 16px;
}

.App-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  padding-left: 16px;
  width: 408px;
  z-index: 1;
  background-color: #fff;
  transform: translate(-100%, 0);
  transition: transform 0.3s ease-in-out;
}

.App.is-menu-open .App-nav {
  transform: translate(0, 0);
}

.App-navLink {
  color: var(--color-gray-50);
  transition: color 0.1s ease-in-out;
  display: block;
  position: relative;
}

.App-navLink:hover,
.App-navLink:active,
.App-navLink[aria-current] {
  color: var(--color-black);
}

.App-navBottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.App-navArtworkAdd {
  position: absolute;
  bottom: 0;
  right: 0;
}

.App-main {
  height: 100vh;
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
  background-color: var(--theme-base-bg);
  color: var(--theme-base-text);
  transition: background-color 0.2s ease-in-out;
  z-index: 0;
}

.App-main--fixed {
  z-index: 5; /** Above navigation */
}

.App-fixedPanel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.App-detailPanel {
  z-index: 6; /* Above navigation and mainInner and roomPanel */
  position: relative;
}

.App-portal {
  position: relative;
  z-index: 7;
}

.App-compatError {
  position: fixed;
  background: #000;
  color: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.CompatError {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.CompatError-msg {
  max-width: 800px;
}

.CompatError-title {
  padding-top: 32px;
}

.AppPage {
  background-color: var(--theme-block-bg);
}

/* Context Menu */

.ContextMenu {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  width: 120px;
}

.ContextMenu-item {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  height: 40px;
  padding-top: 9px;
  padding-left: 16px;
  padding-right: 16px;
  transition: background-color 0.1s ease-in-out;
  outline: 0;
}

.ContextMenu-item:hover,
.ContextMenu-item:focus {
  background-color: var(--color-gray-5);
}

.ContextMenu-item:active {
  background-color: var(--color-gray-10);
}

.ContextMenu-itemLabel {
  flex-grow: 1;
}

/* Password Validation */

.PasswordValidation {
  margin-top: 20px;
  color: #666;
}

.PasswordValidation p + p {
  margin-top: 10px;
}

.PasswordValidation ul {
  list-style: disc;
  padding-left: 20px;
  margin-top: 5px;
}

.ArtworkDetail {
  display: flex;
  height: 100vh;
  position: relative;
  z-index: 0;
}

.ArtworkDetail-leftCol,
.ArtworkDetail-rightCol {
  width: 50%;
  flex-grow: 1;
}

.ArtworkDetail-leftCol {
  padding: 16px;
  position: relative;
}

/* 
 * Copied from ArtworkForm
 * *******************************
*/
.ArtworkDetail-leftColInner {
  width: 100%;
  height: calc(50% - 16px);
}

.ArtworkDetail-rightCol {
  position: relative;
}

.ArtworkDetail-rightColInner {
  overflow-y: auto;
  height: 100%;
}

.ArtworkDetail-rightCol::before {
  position: absolute;
  content: '';
  border-left: 1px solid #000;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.ArtworkDetail-title {
  padding-left: 16px;
  height: calc(50vh - 41px - 90px);
  padding-right: 40px;
}

.ArtworkDetail-titleInner {
  padding-top: 8px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  position: relative;
  z-index: 2;
}

.ArtworkDetail-areaTitle {
  position: fixed;
  padding-top: 8px;
  padding-left: 16px;
  z-index: 1;
  left: calc((100vw - 40px) / 2 + 40px);
  right: 40px;
}

.DetailPanel .ArtworkDetail-areaTitle {
  left: calc((100vw - 162px) / 2 + 162px);
}

.ArtworkDetail-areaTitleInner {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ArtworkDetail-dateAcquired {
  position: absolute;
  bottom: 10px;
  left: 16px;
}

.ArtworkDetail-imageDimensions {
  position: absolute;
  bottom: 10px;
  left: 16px;
}

.ArtworkDetail-metaBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  top: -1px;
  background-color: #fff;
  padding-top: 90px;
}

.ArtworkDetail-metaBar::before {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #000;
}

.ArtworkDetail-meta {
  display: flex;
}

.ArtworkDetail-meta .Button:focus {
  box-shadow: none;
  background-color: var(--theme-btn-secondary-bg-active);
}

.ArtworkDetail-desc {
  padding: 11px 16px 16px;
}

.ArtworkDetail-desc .f-body-02 + .f-body-02 {
  margin-top: 12px;
}

.TextField {
  border-top: 1px solid var(--theme-border-aux);
  transition: background-color 0.1s ease-in-out;
}

.TextField.is-focused {
  background-color: var(--theme-input-hover);
}

.TextField:hover {
  background-color: var(--theme-input-hover);
}

.TextField-inner {
  display: flex;
}

.TextField-input {
  height: 40px;
  padding-left: 16px;
  width: 0;
  border: 0;
  flex-grow: 1;
  background-color: transparent;
  resize: none;
}

.TextField-input::placeholder {
  color: var(--theme-block-text);
  opacity: 1;
}

.TextField--error .TextField-input::placeholder {
  color: var(--color-ui-error-50);
}

.TextField-input:focus {
  outline: 0;
}

.TextField-input:focus::placeholder {
  color: #666666;
}

.TextField-note {
  color: #666666;
  padding-top: 14px;
  padding-right: 16px;
  padding-left: 5px;
}

/* .TextField-note.is-warning { */
/* color: var(--color-ui-warning-85); */
/* } */

.TextField-note.is-error {
  color: var(--color-ui-error-50);
}

.TextField--multiline {
  min-height: 10em;
  display: flex;
  align-items: stretch;
  flex-grow: 1;
}

.TextField--multiline .TextField-inner {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
}

.TextField--multiline .TextField-input {
  height: 100%;
  padding-top: 10px;
}

.TextField-error {
  background-color: #fbebe9;
  border-bottom: 1px solid #dc3723;
  color: #000;
  padding: 8px;
}

/**
 * Firefox Mac and Windows browsers renders a different line-height to other browsers.
 * so we compensate for it with transforms
 */
:root {
  --font-alignment-offset: 0;
  /* Tested via font-size: 212px -> 25px difference */
  --SOEHNE-ALIGNMENT-OFFSET: 0.1179;
  /* Tested via font-size 200px -> 23px difference */
  --JJANNON-ALIGNMENT-OFFSET: 0.115;
}

.f-heading-01 {
  /* s400-18 */
  --font-size: 92px;
  font-family: 'Soehne', 'Helvetica', sans-serif;
  font-size: var(--font-size);
  line-height: 86px;
  letter-spacing: -0.04em;
  font-feature-settings: 'ss01' on;
  font-weight: 400;
  margin: 0;
  transform: translate(
    0,
    calc(var(--font-size) * var(--font-alignment-offset))
  );
}

.has-font-alignment-issue .f-heading-01 {
  --font-alignment-offset: var(--SOEHNE-ALIGNMENT-OFFSET);
}

.f-heading-02 {
  /* jjd400-04--d */
  --font-size: 48px;
  font-family: 'JJannon Display', serif;
  font-feature-settings: 'ss01' on;
  font-variant-ligatures: discretionary-ligatures;
  font-size: var(--font-size);
  line-height: 50px;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  margin: 0;
  transform: translate(
    0,
    calc(var(--font-size) * var(--font-alignment-offset))
  );
}

.has-font-alignment-issue .f-heading-02 {
  --font-alignment-offset: var(--JJANNON-ALIGNMENT-OFFSET);
}

/* ".f-body" is used for <body> and excludes the alignment transform */
.f-body,
.f-body-01 {
  /* s400-08 */
  --font-size: 17px;
  font-size: var(--font-size);
  line-height: 21px;
  letter-spacing: -0.01em;
  font-family: 'Soehne', 'Helvetica', sans-serif;
  font-feature-settings: 'ss01' on, 'ss02' on;
}

.f-body-01 {
  /* Set as a px transform so the computed value can be easily read in JS  */
  --font-alignment-px-offset: calc(
    var(--font-size) * var(--font-alignment-offset)
  );
  transform: translate(0, var(--font-alignment-px-offset));
}

input.f-body-01 {
  /* Set as a px transform so the computed value can be easily read in JS  */
  transform: none;
}

.has-font-alignment-issue .f-body-01 {
  --font-alignment-offset: var(--SOEHNE-ALIGNMENT-OFFSET);
}

.f-body-02 {
  /* jj400-08 */
  font-family: 'JJannon', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
  max-width: 40em;
  word-wrap: break-word;
}

.f-body-02 > p:not(:first-child) {
  margin-top: 0.66em;
}

.f-subhead-01 {
  --font-size: 17px;
  font-size: var(--font-size);
  line-height: 20px;
  font-family: 'Soehne', 'Helvetica', sans-serif;
  font-weight: 600;
  letter-spacing: -0.01em;
  -webkit-font-smoothing: antialiased;
  transform: translate(
    0,
    calc(var(--font-size) * var(--font-alignment-offset))
  );
}

.has-font-alignment-issue .f-subhead-01 {
  --font-alignment-offset: var(--SOEHNE-ALIGNMENT-OFFSET);
}

.f-subhead-02 {
  font-family: 'JJannon', serif;
  font-style: normal;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
}

.f-caption-01 {
  /* s400-03 */
  font-size: 12px;
  line-height: 14px;
  font-family: 'Soehne', 'Helvetica', sans-serif;
  letter-spacing: 0;
}

.f-caption-02 {
  font-family: 'JJannon', serif;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.025em;
}

.num {
  font-feature-settings: 'tnum' on, 'lnum' on;
}

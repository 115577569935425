.LoginScreen {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: var(--theme-base-bg);
  --theme-border-aux: rgba(0, 0, 0, 0.2);
}

.LoginScreen.is-loading {
  background-color: #fff;
}

.LoginScreen-imageCol {
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.LoginScreen.is-loading .LoginScreen-imageCol {
  opacity: 0;
}

.LoginScreen-imageBg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.LoginScreen-logo {
  position: absolute;
  bottom: 15px;
  left: 13px;
}

.LoginScreen-formCol {
  width: 50%;
  border-left: 1px solid #000;
  left: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  transition: border-color 0.1s ease-in-out;
}

.LoginScreen.is-loading .LoginScreen-formCol {
  border-left-color: transparent;
}

.LoginScreen-loader {
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 1px;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  transform: translateY(0%) scaleY(0);
  transform-origin: 0 0;
}

.LoginScreen.is-loading .LoginScreen-loader {
  opacity: 1;
  animation: increase 3s infinite cubic-bezier(0.5, 0, 0.3, 1);
}

.LoginScreen-title {
  height: 50vh;
  /* border-bottom: 1px solid #000; */
  padding-left: 16px;
}

.LoginScreen-form {
  height: 50vh;
  display: block;
  position: relative;
  padding-bottom: 40px;
  border-top: 1px solid var(--theme-border);
  transition: border-color 0.1s ease-in-out;
  overflow: hidden;
}

.LoginScreen.is-loading .LoginScreen-form {
  border-top-color: transparent;
}

.LoginScreen-formInner > .TextField:first-child {
  margin-top: -1px;
}

.LoginScreen-footer {
  background-color: var(--color-block-bg);
  color: var(--color-block-text);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
}

.LoginScreen-formNote {
  color: #666;
  padding-top: 14px;
}

.LoginScreen-formNote.is-error {
  color: var(--color-ui-error-50);
}

.FileUploadZone {
  height: 100%;
}

.FileUploadZone:focus {
  outline: 0;
}

.FileUploadZone.is-dropping {
  background-color: var(--color-ui-info-10);
}

.FileUploadZone-instruction {
  padding-top: 9px;
  padding-left: 16px;
}

.ScaleSlider {
  width: 100%;
  height: 100%;
}

.ScaleSlider-inner {
  position: absolute;
  /* bottom: 20px;
  left: 0;
  right: 0; */
}

.ScaleSlider-meter {
  width: 100px;
  height: 1px;
  /* background-color: #3c7dc3; */
  background-color: #000;
  position: absolute;
  left: 0;
}

.ScaleSlider--vertical .ScaleSlider-meter {
  width: 1px;
  height: 100px;
}

.ScaleSlider--warning .ScaleSlider-meter {
  background-color: #dc3723;
}

.ScaleSlider-notch {
  position: absolute;
  background-color: white;
  width: 1px;
  height: 1px;
}

.ScaleSlider-handleArea {
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  top: 50%;
  position: absolute;
  cursor: grab;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

.ScaleSlider--vertical .ScaleSlider-handleArea {
  left: 50%;
  top: auto;
}

.ScaleSlider-handle {
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #3c7dc3; */
  background-color: #000;
  position: absolute;
}

.ScaleSlider--warning .ScaleSlider-handle {
  background-color: #dc3723;
}

.ScaleSlider-handleArea:active {
  cursor: grabbing;
}

.ScaleSlider-handle::before {
  content: '';
  position: absolute;
  height: 8px;
  background-color: #fff;
  width: 1px;
  top: 8px;
  left: 9px;
}

.ScaleSlider-handle::after {
  content: '';
  position: absolute;
  height: 8px;
  background-color: #fff;
  width: 1px;
  top: 8px;
  right: 9px;
}

.ScaleSlider--warning .tooltip {
  --background-color: #dc3723;
}

.ScaleSlider .tooltip--top {
  top: -5px;
}

.ScaleSlider .tooltip--bottom {
  bottom: -5px;
}

.ScaleSlider .tooltip--right {
  right: -5px;
}

.ScaleSlider .tooltip--left {
  left: -5px;
}

/* Lifted from .RoomComposer-mid */
.RoomPreview {
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Lifted from .RoomComposer-inner */
.RoomPreview-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* Lifted from .RoomComposer-wall */
.RoomPreview-wall {
  width: 1600px;
  height: 900px;
  border: 1px solid #000;
  position: absolute;
}

/* Lifted from .RoomComposer-wallLine */
.RoomPreview-wallLine {
  width: 500px;
  height: 0;
  border-top: 1px solid #000;
  position: absolute;
  transition: border-color 0.2s ease;
}

/* Animatable for framer-motion AnimatePresence (can layer if neccessary) */
.RoomPreview-artworks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* opacity: 0.2; */
}

.ImageUploadZone {
  height: 100%;
  display: flex;
}

.ImageUploadZone-dropPlaceholder {
  width: 256px;
  height: 320px;
  background-color: var(--color-gray-5);
  transition: background-color 0.1s ease-in-out;
  margin-top: 16px;
  margin-left: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border-colors 0.1s ease-in-out;
}

.ImageUploadZone-dropPlaceholder:hover {
  border-color: #000;
}

.ImageUploadZone.is-dragging .ImageUploadZone-dropPlaceholder {
  background-color: var(--color-brand-green-10);
}

.ImageUploadZone.is-error .ImageUploadZone-dropPlaceholder {
  background-color: var(--color-ui-error-10);
}

.ImageUploadZone-col {
  width: 50%;
}

.ImageUploadZone-title {
  max-width: 490px;
  padding-left: 16px;
}

.ImageUploadZone-rightCol {
  border-left: 1px solid #000;
  position: relative; /* for -loader */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.ImageUploadZone-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.ImageUploadZone-loader {
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 1px;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  transform: translateY(0%) scaleY(0);
  transform-origin: 0 0;
}

.ImageUploadZone.is-uploading .ImageUploadZone-loader {
  opacity: 1;
  animation: increase 3s infinite cubic-bezier(0.5, 0, 0.3, 1);
}

.ImageUploadZone.is-loading .ImageUploadZone-rightCol {
  border-left-color: #e6e6e6;
}

.ImageUploadZone.is-error .ImageUploadZone-rightCol {
  border-left-color: #dc3723;
}

.ImageUploadZone-uploadBtn {
}

.ImageUploadZone-uploadNote {
  padding-left: 16px;
  padding-bottom: 12px;
  color: #666;
}

.ImageUploadZone.is-error .ImageUploadZone-uploadNote {
  color: #dc3723;
}

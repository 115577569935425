.RoomsIndexRow {
  display: flex;
  padding-top: 6px;
  padding-bottom: 16px;
  text-decoration: none;
  color: inherit;
  position: relative;
  transition: background-color 0.1s ease-in-out;
}

.RoomsIndexRow:hover {
  background-color: var(--color-gray-5);
}

.RoomsIndexRow:active {
  background-color: var(--color-gray-10);
}

.RoomsIndexRow-title {
  width: 25%;
  padding-left: 16px;
}

.RoomsIndexRow-meta {
  width: 25%;
  padding-left: 16px;
  color: #999;
}

.RoomsIndexRow-metaDefault,
.RoomsIndexRow-metaHover {
  transition: opacity 0.1s ease-in-out;
}

.RoomsIndexRow-metaHover {
  opacity: 0;
  /* Shift it up to the first row */
  transform: translate(0, -100%);
}

.RoomsIndexRow:hover .RoomsIndexRow-metaHover {
  opacity: 1;
}

.RoomsIndexRow:hover .RoomsIndexRow-metaDefault {
  opacity: 0;
}

.RoomsIndexRow-thumbs {
  width: 50%;
  padding-left: 16px;
  padding-top: 9px;
  overflow: hidden;
}

.RoomsIndexRow-thumbsInner {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -16px;
  height: 100px;
}

.RoomsIndexRow-thumbsInner > * {
  margin-left: 16px;
}

.RoomsIndexRow-deleteBtn {
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.RoomsIndexRow:hover .RoomsIndexRow-deleteBtn {
  opacity: 1;
}

.RoomsIndexRow-deleteBtn .Button {
  color: #fff;
  background-color: var(--color-ui-error-50);
  transition: background-color 0.1s ease-out;
}

.RoomsIndexRow-deleteBtn .Button:hover {
  background-color: var(--color-ui-error-60);
}

.RoomsIndexRow-deleteBtn .Button:focus {
  box-shadow: none;
}

.RoomsIndexRow-extraCount {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  height: 40px;
  padding-top: 10px;
  width: 40px;
  text-align: center;
}

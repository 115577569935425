.ConfirmDialog {
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ConfirmDialog-box {
  background-color: var(--theme-block-bg);
  color: var(--theme-block-text);
  width: 400px;
}

.ConfirmDialog-inner {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 38px;
}

.ConfirmDialog-title {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ConfirmDialog-msg {
  margin-top: 16px;
}

.ConfirmDialog-msg .TextField {
  margin-left: -16px;
  margin-right: -16px;
}

.ConfirmDialog-msg .TextField:first-child {
  margin-top: 10px;
}

.ConfirmDialog-actions {
  display: flex;
  justify-content: space-between;
}

.ConfirmDialog-actionsInner {
  display: flex;
}

.ConfirmDialog-footerNote {
  padding-left: 16px;
  padding-bottom: 12px;
  display: flex;
  align-items: flex-end;
}

.ConfirmDialog-footerNote .is-error {
  color: var(--color-ui-error-50);
}

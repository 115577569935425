* {
  box-sizing: inherit;
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

.App {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Soehne', 'Helvetica', sans-serif;
  color: var(--theme-base-text);
  background-color: var(--theme-base-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
  font-family: 'Soehne', 'Helvetica', sans-serif;
  line-height: 1;
  font-size: inherit;
  padding: 0;
  outline: 0;
  background: none;
  appearance: none;
  border: 0;
  text-align: left;
  color: currentColor;
}

b {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: top;
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

input {
  appearance: none;
  border: 0;
}

.LazyImage {
  position: relative;
  z-index: 0;
  background: #eee;
}

.LazyImage.has-loaded {
  background-color: transparent;
}

.LazyImage.has-placeholder {
  background-color: transparent;
}

.LazyImage-img {
  position: relative;
}

.LazyImage.should-animate .LazyImage-img {
  transition: opacity 1s ease-out;
}

.LazyImage-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.LazyImage-imgPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

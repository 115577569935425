.ArtworkFileRow {
  height: 40px;
  padding-left: 16px;
  border-bottom: 1px solid var(--theme-border-aux);
  display: flex;
  justify-content: space-between;
}

.ArtworkFileRow--zero {
  border-bottom: 0;
  pointer-events: none;
}

.ArtworkFileRow-inner {
  padding-top: 9px;
  flex-grow: 1;
}

.ArtworkFileRow:hover {
  background-color: var(--theme-btn-secondary-bg-hover);
}

.ArtworkFileRow-deleteBtn {
  opacity: 0;
  pointer-events: none;
}

.ArtworkFileRow-deleteBtn .Button {
  background-color: transparent;
}

.ArtworkFileRow:hover .ArtworkFileRow-deleteBtn {
  opacity: 1;
  pointer-events: auto;
}

.ArtworkFileRow.is-new {
  background-color: var(--color-ui-info-10);
  border-bottom-color: var(--color-ui-info-50);
}

.ArtworkFileRow.is-failed {
  background-color: var(--color-ui-error-10);
  border-bottom-color: var(--color-ui-error-50);
}

.Toast {
  background: #000;
  color: #fff;
  padding: 0 12px;
  width: 320px;
  display: flex;
  align-items: flex-start;
}

.Toast:hover {
  background-color: #1a1a1a;
}

.Toast-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Toast-btn {
  line-height: 20px;
  color: #fff;
  transition: 0.1s ease-in-out;
  margin-left: 20px;
}

.Toast:hover .Toast-btn {
  opacity: 0.8;
}

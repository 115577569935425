.ArtworkForm {
  display: flex;
  height: 100%;
}

.ArtworkForm-col {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ArtworkForm-imageCol {
  padding: 16px;
  position: relative;
}

.ArtworkForm-imageColInner {
  width: 100%;
  height: calc(50% - 16px);
  /* Support -imageChangeBtnContainer */
  display: flex;
  align-items: flex-start;
}

.ArtworkForm-sizeEstimator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.ArtworkForm-formCol {
  border-left: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
}

.ArtworkForm-formColScroll {
  overflow-y: auto;
  align-items: stretch;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ArtworkForm-title {
  min-height: 50vh;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ArtworkForm-titleInner {
  padding-left: 15px;
  max-width: 400px;
}

.ArtworkForm-tabBar {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}

.ArtworkForm-tabs {
  display: flex;
}

.ArtworkForm-tabs .Button:focus {
  box-shadow: none;
  background-color: var(--theme-btn-secondary-bg-active);
}

.ArtworkForm-form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ArtworkForm-formInner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #000;
  overflow: hidden;
}

.ArtworkForm-formInner > .TextField:first-child {
  margin-top: -1px;
}

.ArtworkForm-fieldGroup {
  display: flex;
}

.ArtworkForm-fieldGroup > * {
  width: 50%;
}

.ArtworkForm-sizeFields {
  display: flex;
  border-top: 1px solid #e6e6e6;
}

.ArtworkForm-sizeField {
  margin-top: -1px;
  flex-shrink: 1;
}

.ArtworkForm-fieldGroup .TextField:not(:first-child),
.ArtworkForm-sizeField:not(:first-child) {
  border-left: 1px solid #e6e6e6;
}

.ArtworkForm-sizeFieldsNote {
  flex-grow: 1;
  text-align: right;
  padding-top: 14px;
  padding-right: 16px;
  color: #666;
}

.ArtworkForm-sizeFieldsNote a {
  color: inherit;
}

.ArtworkForm-sizeFieldsNote a:hover {
  color: #000;
}

.ArtworkForm-descriptionField {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
}

.ArtworkForm-descriptionField > * {
  width: 100%;
}

.ArtworkForm-formFooter {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--theme-block-bg);
  color: var(--theme-block-text);
}

.ArtworkForm-formNote {
  padding-left: 16px;
  padding-top: 14px;
  color: #666;
}

.ArtworkForm-formNote.is-error {
  color: var(--color-ui-error-50);
}

.ArtworkForm-formActions {
  display: flex;
}

.ArtworkForm-uploadZone {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.ArtworkForm-imageChangeBtnContainer {
  position: relative;
}

.ArtworkForm-imageChangeBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.ArtworkForm-imageChangeBtn .tooltip {
  top: -8px;
}

.ArtworkForm-imageChangeBtn:hover .tooltip {
  opacity: 1;
}

.ArtworkForm-imageChangeBtnContainer:hover .ArtworkForm-imageChangeBtn {
  opacity: 1;
}

.ArtworkForm-savingState {
  background-color: var(--theme-block-bg);
  color: var(--theme-block-text);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ArtworkForm-savingState::before {
  content: '';
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #e6e6e6;
}

.ArtworkForm-savingState::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background-color: #000;
  transform: translateY(0%) scaleY(0);
  transform-origin: 0 0;
  animation: increase 3s infinite cubic-bezier(0.5, 0, 0.3, 1);
}

.ArtworkForm-sizeFields {
  flex-wrap: wrap;
}

.ArtworkForm-sizeField .TextField {
  width: 120px;
}

@media screen and (max-width: 1200px) {
  .ArtworkForm-sizeFieldsNote {
    width: 100%;
    padding-left: 16px;
    padding-bottom: 10px;
    text-align: left;
    padding-top: 5px;
  }
}

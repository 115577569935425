.Error404 {
  display: flex;
  height: 100%;
}

.Error404-col {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  padding-left: 16px;
}

.Error404-rightCol {
  border-left: 1px solid var(--theme-border);
}

.Error404-footer {
  display: flex;
  justify-content: space-between;
}

.Error404-footerNote {
  /* padding-left: 16px; */
  padding-top: 8px;
}

.Error404-footerAction {
}

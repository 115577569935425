:root {
  /* Greyscale colors */
  --color-black: #000;
  --color-white: #fff;
  --color-gray-95: #0d0d0d;
  --color-gray-90: #1a1a1a;
  --color-gray-80: #333;
  --color-gray-70: #4d4d4d;
  --color-gray-60: #666;
  --color-gray-50: #808080;
  --color-gray-40: #999;
  --color-gray-30: #b2b2b2;
  --color-gray-20: #ccc;
  --color-gray-10: #e6e6e6;
  --color-gray-5: #f2f2f2;

  /* Brand colors */
  --color-brand-orange-10: #fae5d1;
  --color-brand-orange-15: #f8d8ba;
  --color-brand-yellow-10: #f7edd4;
  --color-brand-yellow-15: #f3e4bf;
  --color-brand-lime-10: #e9efdc;
  --color-brand-lime-15: #dee7cb;
  --color-brand-teal-10: #e0ebe8;
  --color-brand-teal-15: #d1e1dd;
  --color-brand-slate-10: #e0e6eb;
  --color-brand-slate-15: #d1dae1;
  --color-brand-blue-10: #d8e5f3;
  --color-brand-blue-15: #c5d8ed;
  --color-brand-red-10: #f8d7d3;
  --color-brand-red-15: #f5c3bd;
  --color-brand-brown-10: #eae8e1;
  --color-brand-brown-15: #e0ddd2;

  /* UI colors */
  /* --color-ui-warning-85: #40310c; */
  /* --color-ui-warning-50: #e8e87d; */
  --color-ui-error-10: #f8d7d3;
  --color-ui-error-50: #dc3723;
  --color-ui-error-60: #b02c1c;
  --color-ui-info-50: #649b8c;
  --color-ui-info-10: #e0ebe8;
}

.light-mode {
  --theme-base-bg: var(--color-white);
  --theme-base-text: var(--color-black);
  --theme-block-bg: var(--color-white);
  --theme-border: var(--color-black);
  --theme-block-text: var(--color-black);
  --theme-btn-primary-bg: var(--color-black);
  --theme-btn-primary-text: var(--color-white);
  --theme-btn-primary-bg-hover: var(--color-gray-90);
  --theme-btn-primary-bg-active: var(--color-gray-80);
  --theme-btn-primary-bg-disabled: var(--color-black);
  --theme-btn-primary-text-disabled: var(--color-gray-60);
  --theme-btn-secondary-bg: var(--color-white);
  --theme-btn-secondary-text: var(--color-black);
  --theme-btn-secondary-bg-hover: var(--color-gray-5);
  --theme-btn-secondary-bg-active: var(--color-gray-10);
  --theme-btn-secondary-bg-disabled: var(--color-gray-5);
  --theme-btn-secondary-text-disabled: var(--color-gray-50);
  --theme-input-hover: var(--color-gray-5);
  --theme-border-aux: var(--color-gray-10);
  color: var(--theme-base-text);
  background-color: var(--theme-base-bg);
}

.dark-mode {
  --theme-base-bg: var(--color-gray-95);
  --theme-border: var(--color-white);
  --theme-base-text: var(--color-white);
  --theme-block-bg: var(--color-black);
  --theme-block-text: var(--color-white);
  --theme-btn-primary-bg: var(--color-white);
  --theme-btn-primary-text: var(--color-black);
  --theme-btn-primary-bg-hover: var(--color-gray-10);
  --theme-btn-primary-bg-active: var(--color-gray-20);
  --theme-btn-secondary-bg-hover: var(--color-gray-90);
  --theme-border-aux: var(--color-gray-80);
  --theme-link-aux: var(--color-gray-50);
  color: var(--theme-base-text);
  background-color: var(--theme-base-bg);
}

.Button {
  display: flex;
}

.Button:focus {
  outline: 0;
}

.Button--primary {
  background-color: var(--theme-btn-primary-bg);
  color: var(--theme-btn-primary-text);
}

.Button--primary:hover {
  background-color: var(--theme-btn-primary-bg-hover);
}

.Button--primary:active {
  background-color: var(--theme-btn-primary-bg-active);
}

.Button--primary:focus {
  background-color: var(--theme-btn-primary-bg-hover);
  box-shadow: inset 0 0 0 1px var(--theme-btn-primary-bg-hover),
    inset 0 0 0 2px var(--theme-btn-primary-text);
}

.Button--primary[disabled],
.Button--disabled.Button--primary {
  background-color: var(--theme-btn-primary-bg-disabled);
  color: var(--theme-btn-primary-text-disabled);
  cursor: default;
}

.Button--primary[disabled]:hover,
.Button--primary.Button--disabled:hover {
  /* Prevent hover effect */
  background-color: var(--theme-btn-primary-bg-disabled);
}

.Button--secondary {
  background-color: var(--theme-btn-secondary-bg);
  color: var(--theme-btn-secondary-text);
}

.Button--secondary:hover {
  background-color: var(--theme-btn-secondary-bg-hover);
}

.Button--secondary:active {
  background-color: var(--theme-btn-secondary-bg-active);
}

.Button--secondary:focus {
  background-color: var(--theme-btn-secondary-bg);
  box-shadow: inset 0 0 0 1px var(--theme-btn-secondary-text);
}

.Button--secondary[disabled],
.Button--disabled.Button--secondary {
  background-color: var(--theme-btn-secondary-bg-disabled);
  color: var(--theme-btn-secondary-text-disabled);
  cursor: default;
}

.Button--secondary[disabled]:hover,
.Button--secondary.Button--disabled:hover {
  /* Prevent hover effect */
  background-color: var(--theme-btn-secondary-bg-disabled);
}

.Button--withIcon {
  /* padding-left: 0; */
}

.Button-inner {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  height: 40px;
  width: 100%;
  align-items: center;
}

.Button-icon {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button-label {
  padding-right: 16px;
}

.Button-label:first-child {
  /* padding-top: 0; */
  padding-left: 16px;
}

.Button--error {
  background-color: var(--color-ui-error-50);
  color: var(--color-white);
}

.Button--error:focus {
  background-color: var(--color-ui-error-50);
  box-shadow: inset 0 0 0 1px var(--color-ui-error-50),
    inset 0 0 0 2px var(--theme-btn-primary-text);
}

.Button--selected,
.Button--selected:focus.Button--selected:focus {
  background-color: var(--theme-btn-secondary-bg-hover);
  box-shadow: none;
}
